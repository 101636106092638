/* global $ */

const initNav = () => {
  $('#nav-icon').click(function () {
    if ($(this).hasClass('open')) {
      $(this).removeClass('open')
      $('.navbar-brand').removeClass('open')
    } else {
      $(this).addClass('open')
      $('.navbar-brand').addClass('open')
    }
  })
}
$(document).ready(initNav)
