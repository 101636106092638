import { runPage } from '../helpers/runPage'
import { track } from '../helpers/analytics'

const init = () => {
  trackReferralToSegment()
}

const trackReferralToSegment = () => {
  const channel = getShareChannelParam()

  if (channel) {
    track('social_referral', { channel })
  }
}

const getShareChannelParam = () => {
  const urlParams = new URLSearchParams(window.location.search)
  return urlParams.get('share_src')
}

runPage(init, 'home')
