/* global $ */

const initAccordion = () => {
  $('.accordion').accordion({
    heightStyle: 'content',
    active: false,
    collapsible: true,
    beforeActivate: function (event, ui) {
      let currHeader, currContent

      // The accordion believes a panel is being opened
      if (ui.newHeader[0]) {
        currHeader = ui.newHeader
        currContent = currHeader.next('.ui-accordion-content')
        // The accordion believes a panel is being closed
      } else {
        currHeader = ui.oldHeader
        currContent = currHeader.next('.ui-accordion-content')
      }
      // Since we've changed the default behavior, this detects the actual status
      var isPanelSelected = currHeader.attr('aria-selected') == 'true'

      // Toggle the panel's header
      currHeader
        .toggleClass('ui-corner-all', isPanelSelected)
        .toggleClass(
          'accordion-header-active ui-state-active ui-corner-top',
          !isPanelSelected
        )
        .attr('aria-selected', (!isPanelSelected).toString())

      // Toggle the panel's icon
      currHeader
        .children('.ui-icon')
        .toggleClass('ui-icon-triangle-1-e', isPanelSelected)
        .toggleClass('ui-icon-triangle-1-s', !isPanelSelected)

      // Toggle the panel's content
      currContent.toggleClass('accordion-content-active', !isPanelSelected)
      if (isPanelSelected) {
        currContent.slideUp()
      } else {
        currContent.slideDown()
      }

      return false // Cancels the default action
    },
  })
}

$(document).ready(() => {
  if ($('.accordion').length) {
    initAccordion()
  }
})
