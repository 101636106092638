const segmentKey = window.SEGMENT_KEY
const isEnabled = () => !!segmentKey

export const init = (history) => {
  if (!isEnabled()) {
    return
  }

  window.analytics.load(segmentKey)
  startPageTracking(history)
}

// some pages have manual tracking and aren't in this list
const routeNamesForPageTracking = {
  '/training/1': 'training_funnel_pet_name',
  '/training/2': 'training_funnel_pet_age',
  '/training/3': 'training_funnel_pet_breed',
  '/training/4': 'training_funnel_zip',
  '/training/5': 'training_funnel_phone',
  '/training/6': 'training_funnel_email',
  '/training/session/new': 'sub_login',
  '/training/session/otp': 'sub_otp',
  '/training/welcome': 'training_welcome',
  '/training/cta': 'puppy_purple_page',
  '/training/offer': 'offer_page',
  '/training/payment': 'paywall',
  '/quote/1': 'quote_pet_name',
  '/quote/2': 'quote_zip_code',
  '/quote/3': 'quote_pet_breed',
  '/quote/4': 'quote_pet_age',
  '/quote/5': 'quote_email',
  '/users/new': 'account_create',
  '/thanks': 'purchase_complete',
  '/share': 'share',
}

// all pages are in this list so they are captured when tracking back button
const routeNames = {
  ...routeNamesForPageTracking,
  '/payment': 'payment',
}

export const trackPage = (path, properties) => {
  if (!isEnabled()) {
    return
  }

  const name = path || routeNamesForPageTracking[window.location.pathname]
  if (name) {
    window.analytics.page(name, Object.assign(properties || {}, {email: window.gon.email || "none"}))
    window.analytics.track(name, Object.assign(properties || {}, {email: window.gon.email || "none"}))
  }
}

const trackOnPageChange = (history) => {
  history.listen(() => trackPage())
}

const trackOnBackButton = (history) => {
  let previousLocation = window.location.pathname
  history.listen(() => {
    if (history.action === 'POP') {
      const parent = routeNames[previousLocation] || previousLocation
      track('back', { parent })
    }

    previousLocation = window.location.pathname
  })
}

export const startPageTracking = (history) => {
  if (!isEnabled()) {
    return
  }

  trackPage()
  trackOnPageChange(history)
  trackOnBackButton(history)
}

export const page = (name) => {
  if (!isEnabled()) {
    return
  }

  window.analytics.page(name)
}

export const track = (name, properties) => {
  if (!isEnabled()) {
    return
  }
  window.analytics.track(name, properties)
}

export const identify = (traits, klaviyoListId) => {
  if (!isEnabled()) {
    return
  }

  return new Promise((resolve) => {
    window.analytics.identify(
      traits || {},
      {
        integrations: {
          Klaviyo: {
            listId: klaviyoListId,
            confirmOptin: false,
          },
        },
      },
      resolve
    )
  })
}
