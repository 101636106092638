/* global $ */
const initCarousel = () => {
  $('.owl-carousel').owlCarousel({
    loop: false,
    nav: false,
    responsive: {
      0: {
        items: 1,
        stagePadding: 40,
      },
      500: {
        items: 2,
        stagePadding: 40,
        margin: 10,
      },
      1000: {
        items: 3,
        margin: 10,
        stagePadding: 40,
      },
    },
  })
}

$(document).ready(() => {
  if ($('.owl-carousel').length) {
    initCarousel()
  }
})
